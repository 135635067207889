/**
 * Copyright ©2024 Dana Basken
 */

export default class ServiceError extends Error {

  constructor(message: string, public endpoint: string, public code: number) {
    super(message);
  }

}
