/**
 * Copyright ©2024 Dana Basken
 */

import {Logger} from "@d4lton/node-frontend";

// required for any libraries that need window.global, since Vite doesn't provide it (Joyride, for example)
declare global { interface Window {global: any} }
window.global ||= window;

// declare "logger" to be a global
declare global { interface Window {logger: Logger} }
declare global { let logger: Logger; }
window.logger ||= Logger.logger;

// start Logger
Logger.start();

export {};
