/**
 * Copyright ©2024 Dana Basken
 */

import {Config} from "@d4lton/node-frontend";
import ServiceError from "../errors/ServiceError";
import Firebase from "./Firebase";

export default class ServiceClient {

  static async getStandardOptions(method: string, body?: any): Promise<any> {
    const token = await Firebase.auth.currentUser?.getIdToken();
    if (body !== undefined) {
      body = typeof body === "string" ? body : JSON.stringify(body);
    }
    return {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      method: method,
      body: body
    };
  }

  static async request(path: string, method: string, body?: any, abortController?: AbortController, extraOptions?: {}): Promise<any> {
    let options = await this.getStandardOptions(method, body);
    options.signal = abortController?.signal;
    if (extraOptions) {
      options = {...options, ...extraOptions};
    }
    return await fetch(`${Config.get("service.api_url", window.location.origin)}${path}`, options)
      .then(async (response: any) => {
        if (response.status !== 200) { throw new ServiceError(response.statusText, path, response.status); }
        return response.json();
      });
  }

}
