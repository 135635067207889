/**
 * Copyright ©2024 Dana Basken
 */

import React, {useEffect, useState} from "react";

export default function AppLoading(): any {

  const [longRunning, setLongRunning] = useState<boolean>();

  useEffect(() => {
    const timeout = setTimeout(() => setLongRunning(true), 1000);
    return () => { clearTimeout(timeout); };
  }, []);

  return <div className="app-loading">
    {longRunning && <div>LONG RUNNING</div>}
  </div>;

}
