/**
 * Copyright ©2024 Dana Basken
 */

import {useModal} from "mui-modal-provider";
import {toast} from "sonner";
import React, {useEffect} from "react";
import {EventBus} from "@d4lton/node-frontend";
import Page from "../Page/Page";
import ReactBig from "./calendars/ReactBig/ReactBig";
import TMDBEventDialog from "./TMDBEventDialog/TMDBEventDialog";
import "./CalendarPage.css";

export default function CalendarPage(): any {

  const {showModal} = useModal();

  useEffect(() => {
    const registrations = EventBus.registerMany("event:error", onEvent);
    return () => {
      EventBus.unregister(...registrations);
    };
  }, []);

  function onEvent(event: any): void {
    if (event.type === "event:error") { toast(event.error.message); }
  }

  function onCreate(start: Date, end: Date): void {
    const event = {start, end};
    showModal(TMDBEventDialog, {event});
  }

  function onUpdate(event: any): void {
    showModal(TMDBEventDialog, {event});
  }

  return <Page classes={["calendar-page"]}>
    <ReactBig onCreate={onCreate} onUpdate={onUpdate} />
  </Page>;

}
