/**
 * Copyright ©2024 Dana Basken
 */

import {createTheme} from "@mui/material/styles";

const cssVar = (name: string) => getComputedStyle(document.documentElement).getPropertyValue(name).trim();

const theme = createTheme({
  cssVariables: true,
  palette: {
    primary: {
      main: cssVar("--BRAND-PURPLE")
    },
    background: {
      default: "#f5f5f5"
    },
    text: {
      primary: "#808080",
      secondary: "#404040"
    }
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif"
  }
});

export default theme;
