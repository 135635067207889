/**
 * Copyright ©2024 Dana Basken
 */

import {Config, EventBus, State} from "@d4lton/node-frontend";
import Firebase from "./Firebase";

export default class SSEService extends EventTarget {

  private static _eventSource?: EventSource;

  static connect(): void {
    try {
      SSEService.disconnect();
      const token = Firebase.token;
      if (!token) { return; }
      SSEService._eventSource = new EventSource(`${Config.get("service.api_url", window.location.origin)}/api/1/sse?token=${token}`);
      SSEService.addListeners();
    } catch (error: any) {
      logger.error(error.message);
    }
  }

  static onMessage(event: any): void {
    const data = JSON.parse(event.data);
    const match = data.type?.match(/^state:(.+)$/);
    if (match) {
      State.set(match[1], data.value);
    } else {
      EventBus.dispatch(data);
    }
  }

  static onOpen(): void {
    State.set("sse", "connected");
  }

  static onError(): void {
    logger.trace("onError");
  }

  static disconnect(): void {
    SSEService.reset();
    State.set("sse", "disconnected");
  }

  static reconnect(delayMs?: number): void {
    SSEService.disconnect();
    if (delayMs !== undefined) {
      setTimeout(() => { SSEService.connect(); }, delayMs);
    } else {
      SSEService.connect();
    }
  }

  static reset(): void {
    SSEService.removeListeners();
    if (SSEService._eventSource) {
      SSEService._eventSource.close();
      SSEService._eventSource = undefined;
    }
  }

  static addListeners(): void {
    if (SSEService._eventSource) {
      SSEService._eventSource.addEventListener("message", SSEService.onMessage);
      SSEService._eventSource.addEventListener("open", SSEService.onOpen);
      SSEService._eventSource.addEventListener("error", SSEService.onError);
    }
  }

  static removeListeners(): void {
    if (SSEService._eventSource) {
      SSEService._eventSource.removeEventListener("message", SSEService.onMessage);
      SSEService._eventSource.removeEventListener("open", SSEService.onOpen);
      SSEService._eventSource.removeEventListener("error", SSEService.onError);
    }
  }

}
