/**
 * Copyright ©2024 Dana Basken
 */

import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import React from "react";
import {Logger} from "@d4lton/node-frontend";
import Firebase from "../../services/Firebase";
import {AuthHandlerProps} from "../AuthUI";
import GoogleIcon from "./google.svg";
import "./GoogleAuthHandler.css";

const logger = Logger.logger;

export default function GoogleAuthHandler(props: AuthHandlerProps): any {

  const provider = new GoogleAuthProvider();

  async function onClick(): Promise<void> {
    try {
      await signInWithPopup(Firebase.auth, provider);
      if (props.onSuccess) { props.onSuccess(); }
    } catch (error: any) {
      if (props.onError) { props.onError(error); } else { logger.error(error.message); }
    }
  }

  return <div className="google-auth-handler auth-handler">
    <button className="button" onClick={onClick}>
      <div className="logo"><img className="icon" src={GoogleIcon} alt="Google Logo" /></div>
      <div className="text">Sign in with Google</div>
    </button>
  </div>;

}
