/**
 * Copyright ©2024 Dana Basken
 */

import {toast} from "sonner";
import React, {useState} from "react";
import {Button, Dialog, DialogContent, DialogProps, DialogTitle} from "@mui/material";
import {useStore} from "@d4lton/node-frontend";
import EventStore from "../../../stores/EventStore";
import TMDBEpisodeSelector from "./TMDBEpisodeSelector/TMDBEpisodeSelector";
import TMDBSearch from "./TMDBSearch/TMDBSearch";
import TMDBShow from "./TMDBShow/TMDBShow";
import "./TMDBEventDialog.css";

interface TMDBEventDialogProps extends DialogProps {
  onClose?: () => void;
  event?: any;
}

const TMDBEventDialog: React.FC<TMDBEventDialogProps> = (props: TMDBEventDialogProps): any => {

  const [, eventStore] = useStore<any[], EventStore>(EventStore);

  const [event, setEvent] = useState<any>(props.event);

  function close(): void {
    if (props.onClose) { props.onClose(); }
  }

  async function onSubmit(): Promise<any> {
    try {
      if (event?.id) { await eventStore.update(event); }
      if (!event?.id) { await eventStore.create(event); }
      close();
    } catch (error: any) {
      toast(error.message);
    }
  }

  function onEventChange(event: any): void {
    setEvent(event);
  }

  function onEpisodeChange(episode: any): void {
    if (!event) { return; }
    setEvent({...event, episode});
  }

  return <Dialog className="tmdb-event-dialog" fullWidth {...props}>
    <DialogTitle className="tmdb-event-dialog-title">{event?.id ? "Update Event" : "Add Event"}</DialogTitle>
    <DialogContent className="tmdb-event-dialog-content">
      <TMDBSearch event={event} onChange={onEventChange} />
      <TMDBShow show={event?.show} />
      <TMDBEpisodeSelector show={event.show} episode={event.episode} onChange={onEpisodeChange} />
      <div className="tmdb-event-dialog-actions">
        <Button onClick={close} variant="text" color="inherit">Cancel</Button>
        <div className="spacer" />
        <Button type="submit" onClick={onSubmit} variant="contained">{event?.id ? "Save" : "Add"}</Button>
      </div>
    </DialogContent>
  </Dialog>;

};

export default TMDBEventDialog;
