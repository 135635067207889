/**
 * Copyright ©2024 Dana Basken
 */

import React from "react";
import {Theaters, Tv} from "@mui/icons-material";
import "./ReactBigEvent.css";

export default function ReactBigEvent(props: any): any {

  function renderTvEvent(): any {
    return <div className="react-big-event-container">
      <div className="react-big-event-icon"><Tv /></div>
      <div>
        {props.event?.show?.title}{props.event?.episode && ` S${props.event.episode.season_number}`}{props.event?.episode && ` E${props.event.episode.episode_number}`}
      </div>
    </div>;
  }

  function renderMovieEvent(): any {
    return <div className="react-big-event-container">
      <div className="react-big-event-icon"><Theaters /></div>
      <div>
        {props.event?.show?.title}
      </div>
    </div>;
  }

  function renderEvent(): any {
    switch (props.event?.show?.type) {
      case "tv":
        return renderTvEvent();
      case "movie":
        return renderMovieEvent();
    }
  }

  return <div className="react-big-event">
    {renderEvent()}
  </div>;

}
