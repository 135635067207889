/**
 * Copyright ©2024 Dana Basken
 */

import React from "react";
import "./TMDBEpisodeItem.css";
import dayjs from "dayjs";

export type TMDBEpisodeItemProps = {
  episode: any;
};

export default function TMDBEpisodeItem(props: TMDBEpisodeItemProps): any {

  function renderEmptyImage(): any {
    return <div className="tmdb-episode-item-image-empty">no image</div>;
  }

  function renderImage(): any {
    if (!props.episode.still_path) { return renderEmptyImage(); }
    return <img src={`https://image.tmdb.org/t/p/w300${props.episode.still_path}`} alt="episode still image" />;
  }

  function renderTitle(): any {
    if (!props.episode.name) { return <span>&nbsp;</span>; }
    return props.episode.name;
  }

  function renderAirDetails(): any {
    const properties: any[] = [
      {id: "season_number", label: "Season"},
      {id: "episode_number", label: "Episode"},
      {id: "air_date", label: "Aired", type: "date"},
      {id: "runtime", label: "Runtime", units: "m"}
    ];
    return properties
      .map(property => {
        let value = props.episode[property.id];
        switch (property.type) {
          case "date":
            value = dayjs(value).format("MM/DD/YYYY");
            break;
        }
        value = `${property.label} ${value}`;
        if (property.units) { value = `${value}${property.units}`; }
        return value;
      })
      .join(", ");
  }

  function renderTagLine(): any {
    return props.episode.overview;
  }

  return <div className="tmdb-episode-item">
    <div className="tmdb-episode-item-image">{renderImage()}</div>
    <div className="tmdb-episode-item-details">
      <div className="tmdb-episode-item-details-title">{renderTitle()}</div>
      <div className="tmdb-episode-item-details-air-date">{renderAirDetails()}</div>
      <div className="tmdb-episode-item-details-tag-line">{renderTagLine()}</div>
    </div>
  </div>;

}
