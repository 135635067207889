/**
 * Copyright ©2024 Dana Basken
 */

import {EventBus, State, Store} from "@d4lton/node-frontend";
import ServiceClient from "../services/ServiceClient";

export default class EventStore extends Store {

  private _user?: any;

  init(): void {
    this.loading = true;
    EventBus.registerMany("event:added", "event:changed", "event:removed", this.onEvent.bind(this));
    State.register("user", (event: any) => {
      this.value = [];
      this._user = event.value;
      this.refresh();
    });
  }

  async refresh(): Promise<void> {
    return this.action(async () => {
      if (!this._user) { return; }
      const events: any[] = await ServiceClient.request(`/api/1/user/${this._user.uid}/event`, "GET");
      this.value = events
        .map(event => {
          if (event.start && event.end) { return this._makeEvent(event); }
        })
        .filter(it => it);
    });
  }

  private _makeEvent(event: any): any {
    return {
      ...event,
      start: typeof event.start === "string" ? new Date(event.start) : event.start,
      end: typeof event.end === "string" ? new Date(event.end) : event.end
    };
  }

  async create(event: any): Promise<void> {
    return this.action(async () => {
      event = this._makeEvent(event);
      event._update_status = {state: "updating"};
      this.value = [...this.value, event];
      try {
        await ServiceClient.request(`/api/1/user/${this._user.uid}/event`, "POST", event);
      } catch (error: any) {
        EventBus.dispatch({type: "event:error", event, error} as any);
        this.value = this.value.map((it: any) => it.id === event.id ? event : it);
        throw error;
      } finally {
        setTimeout(this.refresh.bind(this));
      }
    });
  }

  async update(event: any): Promise<void> {
    return this.action(async () => {
      event = this._makeEvent(event);
      event._update_status = {state: "updating"};
      this.value = this.value.map((it: any) => it.id === event.id ? event : it);
      try {
        await ServiceClient.request(`/api/1/user/${this._user.uid}/event/${event.id}`, "PATCH", event);
      } catch (error: any) {
        EventBus.dispatch({type: "event:error", what: "update", event, error} as any);
        this.value = this.value.map((it: any) => it.id === event.id ? event : it);
        throw error;
      } finally {
        setTimeout(this.refresh.bind(this));
      }
    });
  }

  async delete(event: any): Promise<void> {
    return this.action(async () => {
      event._update_status = {state: "updating"};
      this.value = this.value.map((it: any) => it.id === event.id ? event : it);
      try {
        await ServiceClient.request(`/api/1/user/${this._user.uid}/event/${event.id}`, "DELETE");
      } catch (error: any) {
        EventBus.dispatch({type: "event:error", what: "update", event, error} as any);
        this.value = this.value.map((it: any) => it.id === event.id ? event : it);
        throw error;
      } finally {
        setTimeout(this.refresh.bind(this));
      }
    });
  }

  onEvent(event: any): void {
    switch (event.type) {
      case "event:created": return this._onEventCreated(event.value);
      case "event:changed": return this._onEventChanged(event.value);
      case "event:removed": return this._onEventRemoved(event.value);
    }
  }

  private _onEventCreated(event: any): void {
    event = this._makeEvent(event);
    if (this.value.find((it: any) => it.id === event.id)) { return this._onEventChanged(event); }
    this.value = [...this.value.filter((it: any) => it.id !== event.id), event];
  }

  private _onEventChanged(event: any): void {
    event = this._makeEvent(event);
    this.value = [...this.value.map((it: any) => it.id === event.id ? event : it)];
  }

  private _onEventRemoved(plan: any): void {
    this.value = [...this.value.filter((it: any) => it.id !== plan.id)];
  }

}
