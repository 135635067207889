/**
 * Copyright ©2024 Dana Basken
 */

import {useModal} from "mui-modal-provider";
import React from "react";
import {Logger} from "@d4lton/node-frontend";
import {AuthHandlerProps} from "../AuthUI";
import EmailAuthDialog, {EmailAuthDialogProps} from "./EmailAuthDialog/EmailAuthDialog";
import EmailIcon from "./email.svg";
import "./EmailAuthHandler.css";

const logger = Logger.logger;

export default function EmailAuthHandler(props: AuthHandlerProps): any {

  const {showModal} = useModal();

  async function onClick(): Promise<void> {
    try {
      const modal = showModal<EmailAuthDialogProps>(EmailAuthDialog, {
        onClose: () => modal.hide(),
        onSuccess: () => props.onSuccess && props.onSuccess(),
        onError: props.onError
      });
    } catch (error: any) {
      if (props.onError) { props.onError(error); } else { logger.error(error.message); }
    }
  }

  return <div className="email-auth-handler auth-handler">
    <button className="button" onClick={onClick}>
      <div className="logo"><img className="icon" src={EmailIcon} alt="Email Logo" /></div>
      <div className="text">Sign in with email</div>
    </button>
  </div>;

}
