/**
 * Copyright ©2024 Dana Basken
 */

import "./init_globals";
import ModalProvider from "mui-modal-provider";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {Toaster} from "sonner";
import React from "react";
import {CssBaseline} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import {Logger} from "@d4lton/node-frontend";
import App from "./App/App";
import Firebase from "./services/Firebase";
import "./index.css";
import theme from "./MuiTheme";

(async () => {
  Logger.start();
  await Firebase.start();
  createRoot(document.getElementById("root") as HTMLElement).render(
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Toaster />
        <ModalProvider>
          <CssBaseline />
          <App />
        </ModalProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
})();
