/**
 * Copyright ©2024 Dana Basken
 */

import React from "react";
import EmailAuthHandler from "./EmailAuthHandler/EmailAuthHandler";
import GoogleAuthHandler from "./GoogleAuthHandler/GoogleAuthHandler";
import "./AuthUI.css";

export type AuthUIProps = {
  email?: boolean;
  google?: boolean;
  onSuccess?: () => void;
  onError?: (error: any) => void;
};

export type AuthHandlerProps = {
  onSuccess?: () => void;
  onError?: (error: any) => void;
};

export default function AuthUI(props: AuthUIProps): any {

  function renderAuthHandlers(): any {
    return <div className="auth-ui-handlers">
      {props.google && <GoogleAuthHandler onError={props.onError} onSuccess={props.onSuccess} />}
      {props.email && <EmailAuthHandler onError={props.onError} onSuccess={props.onSuccess} />}
    </div>;
  }

  return <div className="auth-ui">
    {renderAuthHandlers()}
  </div>;

}
