/**
 * Copyright ©2024 Dana Basken
 */

import {useNavigate} from "react-router-dom";
import React from "react";
import AuthUI from "../../AuthUI/AuthUI";
import Page from "../Page/Page";
import "./TeaserPage.css";

export default function TeaserPage(): any {

  const navigate = useNavigate();

  function onSignInError(error: any): void {
    logger.debug("onSignInError", error.code, error.message);
  }

  function onSignInSuccess(): void {
    navigate("/");
  }

  return <Page classes={["teaser-page"]}>
    <AuthUI google onError={onSignInError} onSuccess={onSignInSuccess} />
  </Page>;

}
