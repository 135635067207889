/**
 * Copyright ©2024 Dana Basken
 */

import React, {useState} from "react";
import {ObjectUtilities} from "@d4lton/node-common";
import TMDBShowSelector from "./TMDBShowSelector/TMDBShowSelector";
import TMDBShowTypeMenu, {TMDBShowTypes} from "./TMDBShowTypeMenu/TMDBShowTypeMenu";
import "./TMDBSearch.css";

export type TMDBSearchProps = {
  event?: any;
  onChange: (event: any) => void;
};

export default function TMDBSearch(props: TMDBSearchProps): any {

  const [type, setType] = useState<TMDBShowTypes>("all");

  function onShowTypeChange(type: TMDBShowTypes): void {
    setType(type);
  }

  function onShowChange(show: any): void {
    if (!props.event) { return; }
    const event = ObjectUtilities.clone(props.event);
    event.show = show;
    props.onChange(event);
  }

  return <div className="tmdb-search">
    <TMDBShowTypeMenu type={type} onChange={onShowTypeChange} />
    <TMDBShowSelector type={type} onChange={onShowChange} />
  </div>;

}
