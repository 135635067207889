/**
 * Copyright ©2024 Dana Basken
 */

import {EmailAuthProvider, signInWithCredential} from "firebase/auth";
import {Field, FieldProps, Form, Formik} from "formik";
import {Props} from "mui-modal-provider/dist/types";
import React from "react";
import {Button, Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import {Logger} from "@d4lton/node-frontend";
import Firebase from "../../../services/Firebase";
import "./EmailAuthDialog.css";

const logger = Logger.logger;

export type EmailAuthDialogProps = {
  onClose?: () => void;
  onSuccess?: () => void;
  onError?: (error: any) => void;
  open: boolean;
} & Props;

export default function EmailAuthDialog({onClose, onSuccess, onError, ...props}: EmailAuthDialogProps): any {

  async function onSubmit(values: any): Promise<any> {
    try {
      const authCredential = EmailAuthProvider.credential(values.email, values.password);
      await signInWithCredential(Firebase.auth, authCredential);
      if (onSuccess) { onSuccess(); }
      close();
    } catch (error: any) {
      if (onError) { onError(error); } else { logger.error(error.message); }
    }
  }

  function close(): void {
    if (onClose) { onClose(); }
  }

  return <Dialog className="email-auth-dialog" {...props}>
    <DialogTitle className="email-auth-dialog-title">Sign in with email</DialogTitle>
    <DialogContent className="email-auth-dialog-content">
      <Formik initialValues={{email: "", password: ""}} onSubmit={onSubmit}>
        {() =>
          <Form className="email-auth-dialog-form">
            <Field name="email">{(props: FieldProps) => <TextField required fullWidth label="Email" {...props.field} />}</Field>
            <Field name="password">{(props: FieldProps) => <TextField required fullWidth type="password" label="Password" {...props.field} />}</Field>
            <div className="email-auth-dialog-actions">
              <Button onClick={close} variant="text" color="inherit">Cancel</Button>
              <div className="spacer" />
              <Button type="submit" variant="contained">Sign In</Button>
            </div>
          </Form>}
      </Formik>
    </DialogContent>
  </Dialog>;

}
