/**
 * Copyright ©2024 Dana Basken
 */

import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {Autocomplete, AutocompleteRenderInputParams, TextField} from "@mui/material";
import {AutocompleteInputChangeReason} from "@mui/material/useAutocomplete/useAutocomplete";
import ServiceClient from "../../../../../services/ServiceClient";
import TMDBShow from "../../TMDBShow/TMDBShow";
import {TMDBShowTypes} from "../TMDBShowTypeMenu/TMDBShowTypeMenu";
import "./TMDBShowSelector.css";

interface TMDBShowAutocompleteProps {
  show?: any;
  type: TMDBShowTypes;
  onChange: (show: any) => void;
}

export default function TMDBShowSelector(props: TMDBShowAutocompleteProps): any {

  const timeout = useRef<any>();

  const [shows, setShows] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShows([]);
  }, [props.type]);

  function onChange(_: any, value: any): void {
    props.onChange(value);
  }

  async function onTextFieldChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    setLoading(true);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      try {
        const query: string[] = [`q=${event.target.value}`];
        query.push(`type=${props.type}`);
        const shows: any[] = await ServiceClient.request(`/api/1/show/search?${query.join("&")}`, "GET");
        setShows(shows);
      } catch (error) {
        setShows([]);
        console.error("Error fetching options:", error);
      } finally {
        setLoading(false);
      }
    }, 1500);
  }

  function onInputChange(event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason): void {
    if (reason === "clear" || reason === "reset") { setShows([]); }
  }

  function renderOption(props: any, option: any, state: any): any {
    return <li {...props} key={state.index}>
      <TMDBShow show={option} />
    </li>;
  }

  function renderInput(params: AutocompleteRenderInputParams): any {
    return <TextField
      autoFocus
      className="tmdb-show-autocomplete-textfield"
      {...params}
      placeholder="Search shows..."
      variant="standard"
      onChange={onTextFieldChange}
    />;
  }

  return <Autocomplete
    className="tmdb-show-autocomplete"
    fullWidth
    selectOnFocus
    filterOptions={options => options}
    options={shows}
    clearOnEscape
    clearOnBlur={false}
    onInputChange={onInputChange}
    getOptionLabel={option => option.title || ""}
    onChange={onChange}
    isOptionEqualToValue={(option: any, value: any) => option.id === value?.id}
    loading={loading}
    value={props.show || null}
    renderOption={renderOption}
    slotProps={{popper: {className: "tmdb-show-autocomplete-popper"}}}
    renderInput={renderInput}
  />;

}
