/**
 * Copyright ©2024 Dana Basken
 */

import React, {useEffect, useState} from "react";
import {MenuItem, Select} from "@mui/material";
import TMDBEpisodeItem from "./TMDBEpisodeItem/TMDBEpisodeItem";
import "./TMDBEpisodeSelector.css";

export type TMDBEpisodeSelectorProps = {
  show: any;
  episode?: any;
  onChange: (episode: any) => void;
};

export default function TMDBEpisodeSelector(props: TMDBEpisodeSelectorProps): any {

  const [episodes, setEpisodes] = useState<any[]>([]);

  useEffect(() => {
    if (!Array.isArray(props.show?.details?.seasons)) { return; }
    const episodes = props.show.details.seasons.reduce((episodes: any[], season: any) => [...episodes, ...season.episodes], []);
    setEpisodes(episodes);
  }, [props.show]);

  function onChange(event: any): void {
    props.onChange(event.target.value);
  }

  function renderEpisodeItem(episode: any): any {
    return <MenuItem key={episode.id} value={episode}>
      <TMDBEpisodeItem episode={episode} />
    </MenuItem>;
  }

  function renderEpisodeItems(): any {
    return episodes.map(renderEpisodeItem);
  }

  function renderSelect(): any {
    if (props.show?.type !== "tv") { return; }
    return <Select
      placeholder="Select episode..."
      fullWidth
      displayEmpty
      MenuProps={{PaperProps: {style: {maxHeight: "15em"}}}}
      renderValue={value => value && <TMDBEpisodeItem episode={value} />}
      value={props.episode ?? episodes[0] ?? ""}
      onChange={onChange}
    >
      {renderEpisodeItems()}
    </Select>;
  }

  return <div className="tmdb-episode-selector">
    {renderSelect()}
  </div>;

}
