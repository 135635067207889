/**
 * Copyright ©2024 Dana Basken
 */

import {initializeApp} from "firebase/app";
import {Auth, getAuth, User} from "firebase/auth";
import {English} from "@d4lton/node-common";
import {Config, EventBus, State} from "@d4lton/node-frontend";
import SSEService from "./SSEService";

export default class Firebase {

  private static _auth: Auth;
  private static _token: string | undefined;

  static async start(): Promise<void> {
    const app = initializeApp(Config.get("firebase"), `popular-view.${Config.get("environment", "unknown")}`);
    Firebase._auth = getAuth(app);
    Firebase.auth.onAuthStateChanged(Firebase.onChange);
    Firebase.auth.onIdTokenChanged(Firebase.onChange);
    setInterval(() => { Firebase.user?.getIdToken(); }, English.ms("1m"));
  }

  static async onChange(user: User | null): Promise<void> {
    Firebase._token = undefined;
    if (user) {
      try {
        Firebase._token = await Firebase.user?.getIdToken();
        State.set("user", user);
        State.set("token", Firebase.token);
        SSEService.connect();
        return;
      } catch (error: any) {
        logger.error(error.message);
        EventBus.dispatch({type: "system:error", message: "An unexpected error occurred during authentication."} as any);
      }
    }
    // we fall through here if no user or an error happened above
    SSEService.disconnect();
    State.set("user", null);
    State.set("token", null);
  }

  static get auth(): Auth {
    return Firebase._auth;
  }

  static get user(): User | null {
    return Firebase.auth.currentUser;
  }

  static get token(): string | undefined {
    return Firebase._token;
  }

}
