/**
 * Copyright ©2024 Dana Basken
 */

import React, {useState} from "react";
import {Button, Menu, MenuItem} from "@mui/material";
import "./TMDBShowTypeMenu.css";

export type TMDBShowTypes = "all" | "tv" | "movie";

export type TMDBShowTypeMenuProps = {
  type?: TMDBShowTypes;
  onChange: (type: TMDBShowTypes) => void;
};

export default function TMDBShowTypeMenu(props: TMDBShowTypeMenuProps): any {

  const options: any[] = [
    {id: "all", label: "All"},
    {id: "tv", label: "TV Shows"},
    {id: "movie", label: "Movies"}
  ];

  const [typeMenuAnchor, setTypeMenuAnchor] = useState<any>();

  function closeTypeMenu(): void {
    setTypeMenuAnchor(null);
  }

  function onSelect(type: TMDBShowTypes): void {
    props.onChange(type);
    closeTypeMenu();
  }

  function renderOption(option: any): any {
    return <MenuItem key={`${option.id}`} selected={props.type === option.id} onClick={() => onSelect(option.id)}>{option.label}</MenuItem>;
  }

  function renderOptions(): any {
    return options.map(renderOption);
  }

  function getButtonLabel(): string {
    const option = options.find(option => option.id === props.type);
    return option?.label;
  }

  function onClick(event: any): void {
    setTypeMenuAnchor(event.currentTarget);
  }

  return <div className="tmdb-show-type-menu">
    <Button className="tmdb-show-type-menu-button" onClick={onClick}>{getButtonLabel()}</Button>
    <Menu disablePortal anchorEl={typeMenuAnchor} open={!!typeMenuAnchor} onClose={closeTypeMenu}>{renderOptions()}</Menu>
  </div>;

}
