/**
 * Copyright ©2024 Dana Basken
 */

import dayjs from "dayjs";
import React from "react";
import {English} from "@d4lton/node-common";
import "./TMDBShow.css";

export type TMDBShowProps = {
  show?: any;
};

export default function TMDBShow(props: TMDBShowProps): any {

  function renderEmptyImage(): any {
    return <div className="tmdb-show-image-empty">no image</div>;
  }

  function renderImage(): any {
    if (!props.show?.id) { return; }
    if (!props.show.backdrop_path) { return renderEmptyImage(); }
    return <img src={`https://image.tmdb.org/t/p/w300${props.show.backdrop_path}`} alt="show backdrop" />;
  }

  function renderTitle(): any {
    if (!props.show?.title) { return <span>&nbsp;</span>; }
    return props.show.title;
  }

  function renderAirDetails(): any {
    const details: string[] = [];
    let airDate: string | undefined = undefined;
    if (props.show?.details?.release_date) { airDate = dayjs(props.show?.details?.release_date).format("YYYY"); }
    if (props.show?.first_air_date) {
      const startDate: string = dayjs(props.show.first_air_date).format("MM/DD/YYYY");
      let endDate: string | undefined = undefined;
      if (props.show.details?.last_air_date) { endDate = dayjs(props.show.details?.last_air_date).format("MM/DD/YYYY"); }
      airDate = endDate && startDate !== endDate ? `${startDate}-${endDate}` : startDate;
    }
    if (airDate) { details.push(airDate); }
    if (props.show?.details?.number_of_seasons && Number.isInteger(props.show.details?.number_of_seasons)) {
      details.push(`${props.show.details.number_of_seasons} season${English.plural(props.show.details.number_of_seasons)}`);
    }
    if (props.show?.details?.number_of_episodes && Number.isInteger(props.show.details.number_of_episodes)) {
      details.push(`${props.show.details.number_of_episodes} episode${English.plural(props.show.details.number_of_episodes)}`);
    }
    if (!details.length) { return <span>&nbsp;</span>; }
    return details.join(", ");
  }

  function renderTagLine(): any {
    if (!props.show?.tagline && !props.show?.overview) { return <span>&nbsp;</span>; }
    return props.show?.tagline || props.show?.overview;
  }

  function renderShow(): any {
    if (!props.show) { return; }
    return <>
      <div className="tmdb-show-image">{renderImage()}</div>
      <div className="tmdb-show-details">
        <div className="tmdb-show-details-title">{renderTitle()}</div>
        <div className="tmdb-show-details-air-date">{renderAirDetails()}</div>
        <div className="tmdb-show-details-tag-line">{renderTagLine()}</div>
      </div>
    </>;
  }

  return <div className="tmdb-show">
    {renderShow()}
  </div>;

}
