/**
 * Copyright ©2024 Dana Basken
 */

import React from "react";
import "./Page.css";

export type PageProps = {
  children: any;
  classes?: any;
};

export default function Page(props: PageProps): any {

  const classes = ["page-content"];
  if (props.classes) {
    if (Array.isArray(props.classes)) {
      classes.push(...props.classes);
    } else {
      classes.push(props.classes);
    }
  }

  return <div className="page">
    <div className={`${classes.join(" ")}`}>
      {props.children}
    </div>
  </div>;

}
