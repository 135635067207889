/**
 * Copyright ©2024 Dana Basken
 */

import dayjs from "dayjs";
import {User} from "firebase/auth";
import {useModal} from "mui-modal-provider";
import {Calendar, dayjsLocalizer, SlotInfo, View} from "react-big-calendar";
import withDragAndDrop, {EventInteractionArgs} from "react-big-calendar/lib/addons/dragAndDrop";
import React, {useState} from "react";
import {useStateChange, useStore} from "@d4lton/node-frontend";
import YesNoDialog from "../../../../dialogs/YesNoDialog";
import EventStore from "../../../../stores/EventStore";
import ReactBigEvent from "./ReactBigEvent/ReactBigEvent";
import "./ReactBig.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

const DnDCalendar = withDragAndDrop(Calendar);
const localizer = dayjsLocalizer(dayjs);

export type ReactBigProps = {
  onCreate: (start: Date, end: Date) => void;
  onUpdate: (event: any) => void;
};

export default function ReactBig(props: ReactBigProps): any {

  const {showModal} = useModal();

  const user = useStateChange<User | null | undefined>("user");

  const [events, eventStore] = useStore<any[], EventStore>(EventStore);

  const [view, setView] = useState<string>("month");

  function onView(view: View): void {
    setView(view);
  }

  function onKeyPressEvent(event: any, keyDownEvent: any): void {
    if (keyDownEvent.code === "Delete" || keyDownEvent.code === "Backspace") {
      showModal(YesNoDialog, {title: "Confirm Event Deletion", body: `Are you sure you want to delete event "${event.show.title}"?`, confirm: () => {
        eventStore.delete(event);
      }});
    }
  }

  function onSelectSlot(slotInfo: SlotInfo): void {
    if (slotInfo.action !== "doubleClick") { return; }
    const start = view === "month" ? dayjs(slotInfo.start).set("hour", 12).toDate() : slotInfo.start;
    props.onCreate(start, dayjs(start).add(1, "hour").toDate());
  }

  function onDoubleClickEvent(event: any): void {
    props.onUpdate(event);
  }

  async function updateEvent(args: EventInteractionArgs<any>): Promise<void> {
    const event = events.find(event => event.id === args.event.id);
    event.start = args.start;
    event.end = args.end;
    try {
      await eventStore.update(event);
    } catch (error: any) {
      logger.error(event, error.message);
    }
  }

  function eventPropGetter(event: any): any {
    const result: any = {};
    if (event._update_status) { result.className = `calendar-page-event-state-${event._update_status.state}`; }
    if (event.owner_id !== user?.uid) { result.className = "calendar-page-event-shared"; }
    return result;
  }

  return <DnDCalendar
    localizer={localizer}
    events={events}
    selectable
    scrollToTime={new Date(new Date().setHours(12, 1))}
    components={{event: ReactBigEvent}}
    eventPropGetter={eventPropGetter}
    onDoubleClickEvent={onDoubleClickEvent}
    onSelectSlot={onSelectSlot}
    onEventDrop={updateEvent}
    onView={onView}
    onEventResize={updateEvent}
    onKeyPressEvent={onKeyPressEvent}
  />;

}
