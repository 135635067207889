/**
 * Copyright ©2024 Dana Basken
 */

import {User} from "firebase/auth";
import {Route, Routes} from "react-router-dom";
import React from "react";
import {useStateChange} from "@d4lton/node-frontend";
import CalendarPage from "../pages/CalendarPage/CalendarPage";
import TeaserPage from "../pages/TeaserPage/TeaserPage";
import AppLoading from "./AppLoading/AppLoading";
import Header from "./Header/Header";
import "./App.css";

export default function App(): any {

  const user = useStateChange<User | null | undefined>("user");

  function render(): any {
    if (user === undefined) { return <AppLoading />; }
    return <>
      <Header />
      <Routes>
        <Route path="/" element={user ? <CalendarPage /> : <TeaserPage />} />
      </Routes>
    </>;
  }

  return <div className="app">
    {render()}
  </div>;

}
