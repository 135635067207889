/**
 * Copyright ©2024 Dana Basken
 */

import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle} from "@mui/material";
import "./YesNoDialog.css";

interface YesNoDialogProps extends DialogProps {
  title: string;
  body: any;
  confirm: () => void;
  onClose?: () => void;
}

const YesNoDialog: React.FC<YesNoDialogProps> = ({title, body, confirm, onClose, ...props}: YesNoDialogProps): any => {

  function close(): void {
    if (onClose) { onClose(); }
  }

  function onConfirmClick(): void {
    confirm();
    close();
  }

  return <Dialog className="yes-no-dialog" fullWidth {...props}>
    <DialogTitle className="yes-no-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <div>{body}</div>
    </DialogContent>
    <DialogActions className="yes-no-dialog-actions">
      <Button variant="text" color="secondary" onClick={onConfirmClick}>Yes</Button>
      <div className="spacer" />
      <Button variant="contained" color="primary" onClick={close}>No</Button>
    </DialogActions>
  </Dialog>;

};

export default YesNoDialog;
