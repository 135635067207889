/**
 * Copyright ©2024 Dana Basken
 */

import {User} from "firebase/auth";
import React from "react";
import {AppBar, Avatar, IconButton, Toolbar} from "@mui/material";
import {useStateChange} from "@d4lton/node-frontend";
import Firebase from "../../services/Firebase";
import Logo from "../../../assets/images/pv_logo.svg";
import "./Header.css";

export default function Header(): any {

  const user = useStateChange<User | null | undefined>("user");

  function signOut(): void {
    Firebase.auth.signOut();
  }

  function renderAvatarButton(): any {
    if (user === undefined) { return; }
    if (user === null) {
      return <Avatar sx={{width: 24, height: 24}} />;
    } else {
      return <Avatar src={user?.user?.photoURL} sx={{width: 24, height: 24}} />;
    }
  }

  return <div className="header">
    <AppBar position="relative">
      <Toolbar>
        <img className="header-logo" src={Logo} alt="logo" />
        <div className="header-spacer" />
        <IconButton onClick={signOut} size="small" color="inherit">
          {renderAvatarButton()}
        </IconButton>
      </Toolbar>
    </AppBar>
  </div>;

}
